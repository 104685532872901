import React from "react"
import SEO from "../components/seo"
import GridItem from "../components/grid-item"
import { BodyCopy, Standfirst } from "../components/styles/textStyles"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <GridItem start="3" end="7">
      <Standfirst>Page Not Found</Standfirst>
    </GridItem>
    <GridItem start="3" end="5" tabletEnd="7">
      <BodyCopy>
        <p>This page no longer exists.</p>
      </BodyCopy>
    </GridItem>
  </>
)

export default NotFoundPage
